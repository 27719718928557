import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import {createChannel} from "../Utils";

class newChannel extends Component {
    constructor(props) {
        super(props);
        this.state={
            isDialogOpen:false,
            channelName:""
        }
    }

open=()=>{
    this.setState({
        isDialogOpen:true
    })
}


    componentWillReceiveProps(nextProps) {
        switch (nextProps.isDialogOpen) {
            case  true:
            {
                this.setState({
                    isDialogOpen:true

                })
                break;
            }
            case false:
            {
                this.setState({
                    isDialogOpen:false

                })
                break;
            }

        }

    }


    render() {
        return (
            <Modal show={this.state.isDialogOpen} onHide={this.handleSubcategoryClose}   >
                <Modal.Header closeButton>
                    <Modal.Title>New Channel</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{textAlign:"center"}}>
                    <img style={{width:"50%"}} src={this.state.channelImage}></img>
                    <InputGroup className="mb-3 col-sm-12"  style={{paddingTop:"10px"}} >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">Channel Name</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            aria-label="Channel Name"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.channelName}
                            onChange={(v)=>{this.setState({channelName:v.target.value})}}

                        />
                    </InputGroup>
                    <InputGroup className="mb-3 col-sm-12" >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">Channel Image</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            aria-label="Channel Image"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.channelImage}
                            onChange={(v) => {
                                this.setState({channelImage: v.target.value})
                            }}

                        />
                    </InputGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{this.props.closeFunction();this.setState({channelName:""})}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={()=>{
                        createChannel(this.state.channelName,this.state.channelImage,()=>{this.props.closeFunction();this.setState({channelName:"",channelImage:""})})
                        }}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

newChannel.propTypes = {};

export default newChannel;
