import React from "react";
import FilterInput from "./FilterInput";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Collapsible from "react-collapsible";
import {FaChevronDown,FaCheck,FaTimes} from 'react-icons/fa'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import '../GlobalVariables'
import {Type} from "react-bootstrap-table2-editor";
import Chart from "react-google-charts";

class StudentDetails extends React.Component{
    constructor(props){
        super(props)
        this.state={
points:"",
            firstName:this.props.history.location.state.firstName,lastName:this.props.history.location.state.lastName,studentId:this.props.history.location.state.studentId,studentData:[],
            subcategoryData:undefined,studentTests:[],testChart:[]


        }
        this.getStudentLessons()
        this.getPoints()
        this.getStudentTests()

    }
        studentColumns = [ {
            dataField: 'date',
            text: 'Date',
            sort: true
        },{
            dataField: 'topic',
            text: 'topic',
            sort: true
        },{
            dataField:'absent',
            formatter:(cell,row,rowIndex)=>{if(cell==true){return <div style={{textAlign:"center"}}><FaCheck color="red" size="1.5em"/></div>}return<div></div>},
            text: 'Absent',
            sort: true,
            editable:false

        } ,{
            dataField:"work",
            formatter:(cell,row,rowIndex)=>{if(cell==true){return <div style={{textAlign:"center"}}><FaCheck color="green" size="1.5em"/></div>}else if(row.absent==false){return <div style={{textAlign:"center"}}><FaTimes color="red" size="1.5em"/></div>}return<div></div>},
            text: 'Work',
            sort: true,

            editable:false

        } ,{
            dataField:"point",
            formatter:(cell,row,rowIndex)=>{if(cell==true){return <div style={{textAlign:"center"}}><FaCheck color="green" size="1.5em"/></div>};return<div></div>},
            text: 'Point',
            sort: true,
            editable:false

        } ,{
            dataField:"late",
            formatter:(cell,row,rowIndex)=>{if(cell==false&&row.absent==false){return <div style={{textAlign:"center"}}><FaCheck color="green" size="1.5em"/></div>};if(row.absent==false){return <div style={{textAlign:"center"}}><FaTimes color="red" size="1.5em"/></div>}return<div></div>},
            text: 'On Time',
            sort: true,
            editable:false

        } ,{
            dataField:"attention",

            formatter:(cell,row,rowIndex)=>{if(cell==true){return <div style={{textAlign:"center"}}><FaCheck color="green" size="1.5em"/></div>}else if(cell==false){return <div style={{textAlign:"center"}}><FaTimes color="red" size="1.5em"/></div>}return<div></div>},
            text: 'Attention',
            sort: true

        } , {
            dataField:"note",
            text: 'Notes',
            editor: {
                type: Type.TEXTAREA
            }}  ];



    getStudentLessons(){
        let formData=new FormData()
        formData.append("action","get student lessons")
        formData.append('studentId', this.state.studentId)
        var url = "http://ohrhatorah.appsenses.com/lesson.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            let worked =0;
            let noWork=0
            let absent=0
            res.map((item)=>{
                item.id=parseInt(item.id)
                if (item.work==1){
                    worked++
                }else if(item.absent==0){
                    noWork++
                }else{
                    absent++
                }

            })
            let data=[['Work', 'Amount Of Days'],['Did His Work',worked],["Didn't Do Work" , noWork],['Absent',absent]

        ]

            this.setState({studentData:res,workPie:data

            })
        })


    }
    getStudentTests(){
        let formData=new FormData()
        formData.append("action","get student tests")
        formData.append('studentId', this.state.studentId)
        var url = "http://ohrhatorah.appsenses.com/test.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            let testChart=[];
            testChart.push( ['tests', 'mark','Class Average'])
            res.map((item,i)=>{
                item.id=parseInt(item.id)
                item.mark=parseInt(item.mark)
                item.average=parseInt(item.average)
                testChart.push([(i+1),item.mark,item.average])

            })
            console.log(testChart)
            this.setState({studentTests:res,testChart:testChart})
        })


    }

    getPoints(){
        let formData=new FormData()
        formData.append("action","get points")
        formData.append('id', this.state.studentId)
        var url = "http://ohrhatorah.appsenses.com/bank.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{

            this.setState({points:res[0].points})
        })


    }

    defaultSorted = [{
        dataField: 'date', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc' // desc or asc
    }];


    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="students">Students</Breadcrumb.Item>
                <Breadcrumb.Item active>{this.state.firstName} {this.state.lastName}</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{textAlign:"center",padding:"15px"}} >
                     <div class="row">
                    <div class="col-sm-5" style={{paddingTop:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">First Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl readOnly
                                aria-label="Channel Name"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.firstName}
                                // onChange={(v)=>{this.setState({channelName:v.target.value})}}

                            />
                        </InputGroup>
                    </div>
                    <div className="col-sm-5" style={{paddingTop: "10px",paddingRight:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingRight:"10px"}}>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Last Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl readOnly
                                aria-label="Channel Image"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.lastName}
                                /*onChange={(v) => {
                                    this.setState({channelImage: v.target.value})
                                }}*/

                            />
                        </InputGroup>
                    </div>
                         <div className="col-sm-2" style={{paddingTop: "10px",paddingRight:"10px"}}>
                             <InputGroup className="mb-3" style={{paddingRight:"10px"}}>
                                 <InputGroup.Prepend>
                                     <InputGroup.Text id="inputGroup-sizing-default">Points</InputGroup.Text>
                                 </InputGroup.Prepend>
                                 <FormControl readOnly
                                              aria-label="Channel Image"
                                              aria-describedby="inputGroup-sizing-default"
                                              value={this.state.points}
                                     /*onChange={(v) => {
                                         this.setState({channelImage: v.target.value})
                                     }}*/

                                 />
                             </InputGroup>
                         </div>
                </div>
<div style={{paddingTop:'15px'}}>
                <Collapsible open={true} trigger={<CollapsibleText text="Student's lessons "/>} onOpening={()=>{}} style={{background:"black"}}>
                    {/*<div style={{paddingTop:'10px',paddingBottom:'10px'}}>
                        <Button onClick={this.handleSubcategoryShow}>
                            Add subcategory
                        </Button>
                    </div>*/}
                    <div style={{paddingTop:'10px'}}>
                    {  <Table  delete={false} defaultSorted={this.defaultSorted} ref={ n => this.existingSubcategoryTableRef = n } columns={this.studentColumns} reload={this.state.reload} data={this.state.studentData} editFunc={(oldValue, newValue, row, column)=>{ if (oldValue!=newValue) {
                        if (column.dataField=="attention") {

                            let attention;
                            if (newValue == "yes") {
                                attention = 1
                            }
                            if (newValue == "no") {
                                attention = 0
                            }

                            let formData = new FormData()
                            formData.append("action", "edit attention")
                            formData.append('id', row.id)
                            formData.append('attention', attention)
                            formData.append('studentId', this.state.studentId)
                            var url = "http://ohrhatorah.appsenses.com/lesson.php"

                            fetch(url, {
                                    method: 'POST',
                                    body: formData

                                }
                            ).then(response=>{ return response.json();}).then(res=>{
                                res.map((item)=>{
                                    item.id=parseInt(item.id)

                                })
                                this.setState({studentData:res})
                            })



                        }else if(column.dataField=="note") {

                            let formData = new FormData()
                            formData.append("action", "edit note")
                            formData.append('id', row.id)
                            formData.append('note', newValue)
                            formData.append('studentId', this.state.studentId)
                            var url = "http://ohrhatorah.appsenses.com/lesson.php"

                            fetch(url, {
                                    method: 'POST',
                                    body: formData

                                }
                            )

                        }



                    }
                    }}
                                                                                                        rowEvents={{
                        onClick: (e, row, rowIndex) => {
                            console.log(e)
                            if (e.type == "click") {
                                //this.props.stateFunc(row.image)
                                this.props.history.push({
                                    pathname: '/lesson-details',
                                    state: { lessonId: row.lesson_id, topic: row.topic,date:row.date}

                                })
                            }
                        }
                    }}
                    />}
                    </div>
                    <div>
                        <Chart
                            width={'500px'}
                            height={'300px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={this.state.workPie}
                            options={{
                                title: 'Daily Work Done',
                                // Just add this option
                                is3D: true,
                            }}
                            rootProps={{ 'data-testid': '2' }}
                        />
                    </div>
                </Collapsible>
</div>
                <div style={{paddingTop:'15px'}}>
                <Collapsible open={true} trigger={<CollapsibleText text={"Tests"}/>}  style={{background:"black"}}>
                    <div style={{paddingTop:'10px'}}>
                    <Table delete={false} switch={false} columns={testColumns} data={this.state.studentTests}
                           rowEvents={{
                           onClick:(e, row, rowIndex) => {

                               if (e.type == "click") {

                                   this.props.history.push({
                                       pathname: '/test-details',
                                       state: {testId: row.testId, topic: row.topic, date: row.date}
                                   })
                               }
                           }
                           }}
                    />
                    </div>
                <div>
                    {this.state.testChart.length>0&&
                    <Chart
                        width={'480px'}
                        height={'200px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.testChart}
                        options={{
                            hAxis: {
                                title: 'Tests',

                            },
                            vAxis: {
                                title: 'Mark',
                            },chartArea: { width: '60%', height: '70%' },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />}
                </div>
                </Collapsible>
                </div>
            </div>
        </div>
    }
}
const testColumns = [
    {
        dataField: 'date',
        text: 'Date',
        sort: true
    },{
        dataField: 'topic',
        text: 'topic',
        sort: true
    },{
        dataField:"mark",
        text: 'Mark',
        sort: true,
        editable:false

    },
    {

    dataField:"note",
    text: 'Notes',
    editor: {
        type: Type.TEXTAREA
    }} ];

const CollapsibleText=(props)=>{
    {/*className='col-sm-3 col-lg-2'*/}  {/*className='col-sm-9 col-lg-10'*/}
    return <span style={{paddingLeft:'15px'}} className="row">
      <span style={{width:"150px"}} > {props.text}</span>  <span><FaChevronDown/></span>
    </span>
}
export default StudentDetails

/*[
                            ['v', 'mark'],
                            [0,95],
                            [1,60],
                            [2, 23],
                            [3, 17],
                            [4, 18],
                            [5, 9],
                            [6, 11],
                            [7, 27],
                            [8, 33],
                            [9, 40],
                            [10, 32],
                            [11, 35],
                        ]*/