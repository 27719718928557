import React, {Component} from 'react';
import {GiTimeBomb} from 'react-icons/gi'
import { Document, Page, Text, View, StyleSheet,PDFViewer,Image } from '@react-pdf/renderer';
import pic from "../time-bomb.png"
import Notifications, {notify} from 'react-notify-toast';

// Create styles
const styles = StyleSheet.create({
    page: {
        width:900,
        flexDirection: 'row',

    },
    section: {
        margin: 3,
        padding: 3,
        flexGrow: 0
    },
    seat:{
height:'100', minWidth:"120",padding:"3",margin:5,borderRadius: "15",border: "2",flex:1,flexDirection:"column",justifyContent:"space-between"
    }
});
class Seat extends Component{
    constructor(props){
        super(props);
    }

    render()
    {
        return <div style={{textAlign:"center",position:"relative",height:"130px",display:"inline-block", width:"150px",border:"2px solid black",padding:"4px",margin:"10px",borderRadius: "15px"}}>
            <div>
            <img style={{width:"28px",height:"28px",padding:"4px",float:"left"}} src="https://cdn3.iconfinder.com/data/icons/wpzoom-developer-icon-set/500/140-128.png"/>
            <img style={{width:"24px",height:"24px",float:"right"}} src="https://cdn0.iconfinder.com/data/icons/woocons1/Checkbox%20Empty.png"/>
<div style={{textAlign:"center",display:"flex"}}>
            {this.props.name}
            <br/>
            {this.props.lastName}
</div>
            <div style={{position: "absolute",
                bottom: "0",width:"100%"}}>
            <img style={{width:"32px",height:"32px",float:"right",padding:"4px"}} src="https://cdn2.iconfinder.com/data/icons/fatcow/32x32/ear_listen.png"/>
            <GiTimeBomb size="1.5em" style={{float:"left"}} />
            </div>
            </div>
        </div>
    }
}
class Seat2 extends Component{
    constructor(props){
        super(props);
    }

    render()
    {

        return <View style={[styles.seat,this.props.name=="Rebbi"?{minWidth:250}:this.props.name=="space"&&{border:"0"}]}>
            {this.props.name!="Rebbi"&&this.props.name!="space"&& <View style={{flex:1,flexDirection:"row",position:"fixed",top:0,flexWrap: "wrap",flexFlow:"wrap"}}>
                <Image style={{width:"24",height:"24",padding:"2",paddingRight:4}} src="https://cdn3.iconfinder.com/data/icons/wpzoom-developer-icon-set/500/140-128.png"/>
                 <View style={{flex:1,flexGrow: 1,flexWrap:"wrap"}}>
                   <Text break style={{display:"block",fontSize:12,flexWrap:1}}>
                    {this.props.name+" "+
                    this.props.lastName}
                   </Text>
                </View>
                    <Image style={{width:"20",height:"20",padding:2}} src="https://cdn0.iconfinder.com/data/icons/woocons1/Checkbox%20Empty.png"/>
                </View>}
            { (this.props.name!="Rebbi"&&this.props.name!="space")&&<View style={{maxHeight:35,width:"100%",flex:1,flexDirection:"row",position:"fixed",bottom:0,justifyContent:"space-between",alignSelf:"flex-end",alignItems:"flex-end"}}>
                        <Image style={{width:"24",height:"24",padding:"1",position:"fixed",bottom:0}} src={pic}/>

                        <Image style={{width:"24",height:"24",padding:"1"}} src="https://cdn2.iconfinder.com/data/icons/fatcow/32x32/ear_listen.png"/>
                </View>}

        </View>

    }
}

class SeatingChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            students: [], rows: [],classes:[]
        }
        this.getStudents()

    }
    componentDidMount() {
        notify.show('Building Seating Chart PDF');
    }
    onLoadSuccess=()=>{

notify.hide()
        }

    getStudents = () => {
        let classes=[]
        let ids=[1,2,3]
        ids.map((id)=>{
        let formData = new FormData()
        formData.append("action", "get class for seating")
        formData.append('id', id)
        var url = "http://ohrhatorah.appsenses.com/students.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(res => {
            return res.json()
        }).then(res => {
            // console.log(res)
            let row = "a"
            let rows = []
            let taken = false;
            res.forEach((a) => {
                if (a.seat!="ee"){
                    a.width=5
                }else{
                    a.width=50
                }
                a.id = parseInt(a.id)
                let currentRow = a.seat.charAt(1)
                rows.map((r) => {
                    if (r.length > 0) {
                        if (r[0].seat.charAt(1) == currentRow) {
if(a.seat=="ee"){
    r.push({first_name:"Rebbi",last_name:""})
}
                            if(a.last_name=="Pollack"){
                                r.push({first_name:"space",last_name:""})
                            }
                            r.push(a)
                            taken = true
                        }
                    }
                })
                if (taken == false) {
                    if(a.last_name=="Pollack"){
                        rows.push([{first_name:"space",last_name:"",seat: "be"},a])
                    }else {
                        rows.push([a])
                    }

                }
                taken = false
            })
            console.log(rows)


            classes.push(rows)
            if(id==3) {
                this.setState({students: res, rows: rows, classes: classes})
            }
        })

        })
    }


    render() {


        {/*let seats=this.state.students.map(student=><Seat id={student.id} name={student.first_name} lastName={student.last_name}></Seat>)*/
        }

        return (
<div style={{textAlign:"center"}}>


            <PDFViewer style={{width:800 ,height:800}}>
    <Document
        onLoadSuccess={this.onDocumentLoadSuccess}
    >
        {this.state.classes.map((c)=> <Page orientation="landscape" landscape={true} size="A4" style={styles.page}>
            <View style={styles.section}>

                <Chart2 rows={c}/>


            </View>

        </Page>)}

    </Document>
    </PDFViewer>
    <Notifications options={{top:"100px"}} />
</div>
        );
    }

}
  let Chart = (props) => {
        let seats = props.rows.map((row) => <div className='row'>
            {row.map(student => <Seat id={student.id} name={student.first_name} lastName={student.last_name}></Seat>)}
        </div>)
        return <div style={{padding: '15px'}} className="seating-chart">
            {seats}


        </div>
    }
    let Chart2 =
         (props) => {
            let seats = props.rows.map((row) => <View style={{flex:1,flexDirection: "row"}} className='row'>
                {row.map(student => <Seat2 id={student.id} name={student.first_name} lastName={student.last_name} seat={student.seat}></Seat2>)}
            </View>)
            return <View style={{padding: '15px'}} className="seating-chart">
                {seats}

            </View>
        }
{/*<Chart rows={this.state.rows} ></Chart> */}
SeatingChart.propTypes = {};

export default SeatingChart;
