import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import '../GlobalVariables'
import {Type} from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'bootstrap-4-react';

class NewTest extends React.Component{
    info=[];
    constructor(props){
        super(props)
        this.state={
            classId:1,date:"",
            studentData:[],
            subcategoryData:undefined,selectVal:"d",

            classes:[],
            isDialogOpen: false,
            isSubcategoryDialogOpen:false,reload:false,
            tags: [

            ]

        }
        this.getClasses()
        //this.getStudents()



    }
    getClasses(){
        var url2 = new URL("http://ohrhatorah.appsenses.com/getClasses.php")
        fetch(url2).then(response=>{ return response.json();}).then(res=>{
            res.forEach((a)=>{a.id=parseInt(a.id)})
            this.setState({classes:res})

        })
    }
    setClass(){
        let formData=new FormData()
        formData.append("action","get class")
        formData.append('id', this.state.classId)
        var url = "http://ohrhatorah.appsenses.com/students.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            res.map((item)=>{
                item.id=parseInt(item.id)

                this.info.push(item)

            })
            this.setState({studentData:res})

        })
    }


    absentFormatter=(cell,row,rowIndex)=>{
        let data=this.state.studentData
        console.log(row)
        data.map((d)=>{if(d.id==row.id){d.absent=!d.absent}})

        this.setState({studentData:data})
    }
    /*{
        dataField: 'id',
        text: 'Id',
        sort: true
    },*/

    studentColumns = [ {
        dataField: 'first_name',
        text: 'First Name',
        sort: true
    },{
        dataField: 'last_name',
        text: 'Last Name',
        sort: true
    } ,{
        dataField:"point",
        formatter:(cell,row,rowIndex)=>{return<div><input value={cell}  type="number" onChange={(e)=>{this.info.map((item)=>{if(item.id==row.id){item.point=e.target.value}});this.setState({studentData:this.info})}}/></div>},
        text: 'Points',
        sort: true,
        editable:false

    }  , {
        dataField:"note",
        text: 'Notes',
        editor: {
            type: Type.TEXTAREA
        }} ,{
        dataField: 'seat',
        text: 'Seat',
        sort: true
    },{
        dataField:"mark",
        formatter:(cell,row,rowIndex)=>{return<div><input value={cell} type="number" onChange={(e)=>{this.info.map((item)=>{if(item.id==row.id){item.mark=e.target.value}});this.setState({studentData:this.info})}}/></div>},
        text: 'Mark',
        sort: true,
        editable:false

    }];

    save=()=>{
        let  record = {}
        record.class=this.state.classId
        record.date=this.state.formattedDate
        record.topic=this.state.topic
        record.studentData=this.state.studentData
        let   formData = new FormData();
        formData.append("action","save test")
        formData.append('record', JSON.stringify(record))
        // console.log(record)

        fetch("http://www.ohrhatorah.appsenses.com/test.php",
            {
                method: 'POST',
                body: formData

            }).then(response=>{
            this.info=[];
            this.setState({selectVal:"d",classId:"",topic:"",studentData:[],date:""})
        })

    }





    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="tests">Tests</Breadcrumb.Item>
                <Breadcrumb.Item active>New Test</Breadcrumb.Item>

            </Breadcrumb>
            <div >
                <div style={{paddingRight:"30px",paddingBottom:"10px"}}>
                    <Button style={{float:"right"}} onClick={()=>{this.save()}}>
                        Save
                    </Button>
                </div>
                <div className="row" style={{padding:"15px"}}>
                    <div className="col-sm-3" style={{paddingTop:"10px"}}>
                        <Form.CustomSelect lg mb="3" value={this.state.selectVal} onChange={(v)=>{if(v.target.value!="d"){this.setState({classId:v.target.value,selectVal:v.target.value},this.setClass);}}}>
                            {this.state.classes.map((x,y) => <option value={x.id} key={y}>{x.class}</option>)}
                            <option value="d" >Choose Class</option>

                        </Form.CustomSelect>

                    </div>
                    <div className="col-sm-9" style={{paddingTop:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Test Topic</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Class "
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.topic}
                                onChange={(v)=>{this.setState({topic:v.target.value})}}

                            />
                        </InputGroup>
                    </div>

                </div>
                <div className="row">
                    <div style={{textAlign:"center",padding:"20px"}} >

                        <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Date</InputGroup.Text>
                            </InputGroup.Prepend>
                            <DatePicker selected={this.state.date} onChange={(val,a)=>{let d=new Date(val).toISOString().split('T')[0];console.log(d);this.setState({date:val,formattedDate:d})}} />
                        </InputGroup>

                    </div>

                    <div>

                    </div>



                </div>
                {this.state.studentData.length>0&&

                <div style={{padding:"15px"}}><Table selectable={false} delete={false} switch={false} editable={true}
                                                     editFunc={(oldValue, newValue, row, column)=>{
                                                         if(column.text=="Notes"){
                                                             this.info.map((item,index)=>{
                                                                 if(item.id==row.id){
                                                                     item.note=newValue
                                                                 }
                                                             })}


                                                     }}
                                                     columns={this.studentColumns} data={this.state.studentData}

                /></div>}
            </div>
        </div>
    }
}

export default NewTest