import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import Button from "react-bootstrap/Button";
//import {updateSubcategoryName} from "../Utils";
import NewSubcategory from "./newSubcategory";
import {updatePoints} from "../Utils";

class ClassDetails extends React.Component{
    constructor(props){
        super(props)
        // this.getStudents()
        this.state={
           studentData:[],
            newSubcategoryOpen:false}
        this.setClass()
    }
    studentColumns = [ {
        dataField: 'first_name',
        text: 'First Name',
        sort: true
    },{
        dataField: 'last_name',
        text: 'Last Name',
        sort: true
    },{
        dataField: 'points',
        text: 'Points',
        sort: true
    },{
        dataField: 'absent',
        text: 'Days Absent',
        sort: true
    },{
        dataField: 'class',
        text: 'Class',
        sort: true
    }];

    setClass(){

        var url = "http://ohrhatorah.appsenses.com/students.php"

        fetch(url
        ).then(response=>{ return response.json();}).then(res=>{
            res.map((item)=>{
                item.id=parseInt(item.id);
                item.points=parseInt(item.points)

            })

            this.setState({studentData:res})
        })
    }


    getStudents = () => {

        var url = new URL("http://ohrhatorah.appsenses.com/students.php")
        fetch(url).then(response=>{ return response.json();}).then(res=>{
            // console.log(res)
            res.forEach((a)=>{a.id=parseInt(a.id);a.points=parseInt(a.points)})
            this.setState({subcategories:res})
        })

        // this.setState({isSubcategoryDialogOpen:true})
    }

    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Students</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{padding:"15px"}} >

                {this.state.studentData!=undefined? <Table delete={false} editFunc={(oldValue, newValue, row, column)=>{{updatePoints(oldValue, newValue, row, column,()=>{})}}}
                                                           columns={this.studentColumns} data={this.state.studentData}
                                                           rowEvents={{
                                                               onClick: (e, row, rowIndex) => {
                                                                   console.log(e)
                                                                   if (e.type == "click") {
                                                                       //this.props.stateFunc(row.image)
                                                                       this.props.history.push({
                                                                           pathname: '/student-details',
                                                                           state: { class: row.class,studentId: row.id,firstName:row.first_name,lastName:row.last_name, classId: row.id}
                                                                       })
                                                                   }
                                                               }
                                                           }}
                />:""}
            </div>
        </div>
    }

}
export default ClassDetails