import  React from 'react'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import SearchField from "react-search-field";
import {Type} from "react-bootstrap-table2-editor";
import FilterInput from "./FilterInput";
import Table from "./Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {subcategoryColumns,upload,removeUploaded,uploadDelete,createSubcategory} from "../Utils";
import NewSubcategory from './newSubcategory'


class Upload extends React.Component{
    constructor(props){
        super(props)
        this.state={
search:"",
            isDialogOpen:false,reload:false,data:[],
            newSubcategoryOpen:false
        }
        this.search=this.search.bind(this)
        this.remove=this.remove.bind(this)
        this.deleteSelected=this.deleteSelected.bind(this)
        this.nextPage=this.nextPage.bind(this)
    }
    search(){
        let url="https://cors-anywhere.herokuapp.com/https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=50&type=video&videoEmbeddable=true&fields=items(id,snippet(channelId,channelTitle,description,thumbnails,title)),nextPageToken,pageInfo/totalResults&key=AIzaSyAYUZ7WYj24mzwjEzxQy33m8VSvQvfQDrE&q="+encodeURI(this.state.search)
this.getVideosApiCall(url)
    }
    nextPage(){
        let url="https://cors-anywhere.herokuapp.com/https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=50&type=video&videoEmbeddable=true&fields=items(id,snippet(channelId,channelTitle,description,thumbnails,title)),nextPageToken,pageInfo/totalResults&key=AIzaSyAYUZ7WYj24mzwjEzxQy33m8VSvQvfQDrE&pageToken="+this.state.nextPageToken+"&q="+encodeURI(this.state.search)
   this.getVideosApiCall(url)
    }
    getVideosApiCall(url){
        fetch(url).then(res=>{return res.json()}).then(res=>{
            let nextPageToken=res.nextPageToken
            let dat=res.items
            dat.map((item,index)=>{item.videoId=item.id.videoId;item.id=index})
            this.setState({data:dat,nextPageToken:nextPageToken})
            this.videoTableRef.setData(this.state.data)
        })
    }

    videoColumns = [{
        dataField: 'snippet.thumbnails.default.url',
        text: 'Image',
        formatter:(cell,row,rowIndex)=>{
            return (
                <img src={`${cell}`} onClick= {()=>{this.setState({selectedVideo:row.videoId}) }} />
            )}
    }, {
        dataField: 'snippet.title',
        text: 'Title',
        editor: {
            type: Type.TEXTAREA
        },
        sort: true
    },{
        dataField: 'snippet.description',
        text: 'Description',
        editor: {
            type: Type.TEXTAREA
        }
    }];
    handleClose = () =>{
//        upload(this.videoTableRef,this.state.data,this.subRef.handleGetSelectedData())
        upload(this.videoTableRef,this.videoTableRef.state.d,this.subRef.handleGetSelectedData(),(d)=>{this.setState({data:d})})

        this.setState({isDialogOpen:false})}
    handleShow = () => {
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url = new URL("http://appsenses.com/jflix/web/getSubcategories.php")
        fetch(proxyurl+url).then(response=>{ return response.json();}).then(res=>{
            // console.log(res)
            res.forEach((a)=>{a.id=parseInt(a.id)})
            this.setState({subcategories:res,isDialogOpen:true})
        })

        // this.setState({isSubcategoryDialogOpen:true})
    }
    remove(){
       removeUploaded(this.videoTableRef,this.state.data,(d)=>{this.setState({data:d})})
    }
    deleteSelected(){
        uploadDelete(this.videoTableRef,this.state.data,(d)=>{this.setState({data:d})});
       // this.setState({data:this.videoTableRef.state.d})

    }
    addSubcategory(){
        // this.handleSubcategoryShow()
    }

    render(){
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Upload</Breadcrumb.Item>
            </Breadcrumb>
            <NewSubcategory isDialogOpen={this.state.newSubcategoryOpen} closeFunction={()=>{console.log('clicked');this.setState({newSubcategoryOpen:false})}}/>
            <Modal show={this.state.isDialogOpen} onHide={this.handleClose} dialogClassName = 'playerModal'  >
                <Modal.Header closeButton>
                    <Modal.Title>Choose Subcategory</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {this.state.subcategories!=undefined?  <Table ref={ n => this.subRef = n } columns={subcategoryColumns} data={this.state.subcategories}/>:""}


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ ()=>{this.setState({isDialogOpen:false})}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleClose}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <div style={{textAlign:"center",padding:"15px"}}>
                <SearchField
                    placeholder="Search..."
                    style={{width:"50%"}}
                    classNames="test-class"
                    onSearchClick={this.search}
                    onEnter={this.search}
                    onChange={t=>this.setState({search:t})}
                />

            <div style={{paddingTop:'15px'}}>
                {this.state.selectedVideo!= undefined &&  <iframe  style={{width:"40vw",height:"40vh"}} src={`https://www.youtube.com/embed/${this.state.selectedVideo}`}></iframe>}
            </div>
            </div>
            <div style={{padding:"15px"}} >
                <div style={{paddingTop:'10px',paddingBottom:'10px'}}>
                    <Button onClick={()=>{this.setState({newSubcategoryOpen:true})}}>
                        New Subcategory
                    </Button>
                </div></div>
            <div style={{padding:"20px"}}>
                <Table callback={(d)=>{this.setState({data:d})}} ref={ n => this.videoTableRef = n } deleteClick={this.deleteSelected} nextPageClick={this.nextPage} upload={true} uploadClick={this.handleShow} removeUploadedClick={this.remove} columns={this.videoColumns} data={this.state.data} />
            </div>


        </div>
    }

}
export default Upload
// {this.state.data!=undefined &&