import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import {createSubcategory} from "../Utils";

class newSubcategory extends Component {
    constructor(props) {
        super(props);
        this.state={
            isDialogOpen:false,
            subcategoryName:""
        }
    }

open=()=>{
    this.setState({
        isDialogOpen:true
    })
}


    componentWillReceiveProps(nextProps) {
        switch (nextProps.isDialogOpen) {
            case  true:
            {
                this.setState({
                    isDialogOpen:true

                })
                break;
            }
            case false:
            {
                this.setState({
                    isDialogOpen:false

                })
                break;
            }

        }
       /* if(nextProps.isDialogOpen==true){
            this.setState({
                isDialogOpen:true

            })
        }*/

    }


    render() {
        return (
            <Modal show={this.state.isDialogOpen} onHide={this.handleSubcategoryClose}   >
                <Modal.Header closeButton>
                    <Modal.Title>New Subcategory</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">Subcategory Name</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            aria-label="Channel Name"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.subcategoryName}
                            onChange={(v)=>{this.setState({subcategoryName:v.target.value})}}

                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{this.props.closeFunction();this.setState({subcategoryName:""})}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={()=>{
                        createSubcategory(this.state.subcategoryName,()=>{this.props.closeFunction();this.setState({subcategoryName:""})})
                        }}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

newSubcategory.propTypes = {};

export default newSubcategory;
