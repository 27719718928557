import React from "react";
import Table from "./Table";
import {FaChevronDown,FaCheck,FaTimes} from 'react-icons/fa'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import '../GlobalVariables'
import Button from "react-bootstrap/Button";

class Tests extends React.Component{
    constructor(props){
        super(props)
        this.state={
            studentData:[]

        }
        this.getLessons()
        //this.getPoints()
    }
    studentColumns = [ {
        dataField: 'date',
        text: 'Date',
        sort: true
    },{
        dataField: 'topic',
        text: 'topic',
        sort: true
    } ,{
        dataField: 'class',
        text: 'Class',
        sort: true
    } ];



    getLessons(){
        let formData=new FormData()
        formData.append("action","get tests")
        var url = "http://ohrhatorah.appsenses.com/test.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            res.map((item)=>{
                item.id=parseInt(item.id)

            })
            this.setState({studentData:res})
        })


    }
    deleteTests=()=>{
        let formData=new FormData()
        formData.append("action","delete tests")
        formData.append("ids",this.tableRef.handleGetSelectedData())
        var url = "http://ohrhatorah.appsenses.com/test.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            res.map((item)=>{
                item.id=parseInt(item.id)

            })
            this.setState({studentData:res})
        })



    }



    defaultSorted = [{
        dataField: 'date', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc' // desc or asc
    }];


    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Tests</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{padding:"25px"}}>
                <div style={{paddingBottom:"25px",textAlign:"right"}}>
                    <Button onClick={()=>{ this.props.history.push({
                        pathname: '/new-test'
                    })}}>
                        New Test
                    </Button>
                </div>


                {  <Table  ref={ ref => this.tableRef=ref } switch={false} defaultSorted={this.defaultSorted}  columns={this.studentColumns}  data={this.state.studentData}
                          deleteClick={this.deleteTests}                                       rowEvents={{
                    onClick: (e, row, rowIndex) => {

                        if (e.type == "click") {

                            this.props.history.push({
                                pathname: '/test-details',
                                state: { testId: row.id, topic: row.topic,date:row.date}
                            })
                        }
                    }
                }}
                />}


            </div>
        </div>
    }
}

export default Tests