import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import cookie from "react-cookies";


class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {username:"",password:""};

    }

    render() {
        return (
            <div>
                <Modal show={true}   >
                    <Modal.Header closeButton>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div style={{textAlign:"center",padding:"15px"}}>
                        <input onChange={(e)=>{this.setState({username:e.target.value})}} type="text" placeholder="Username" style={{width:"75%",padding:"5px"}}/>
                        </div>
                        <div style={{textAlign:"center",padding:"15px"}}>
                        <input type="password" onChange={(e)=>{this.setState({password:e.target.value})}} placeholder="Password" style={{width:"75%",padding:"5px"}}/>
            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{
                            let formData=new FormData()
                            formData.append("username",this.state.username)
                            formData.append("password",this.state.password)
                            var url = "http://ohrhatorah.appsenses.com/login.php"

                            fetch(url, {
                                    method: 'POST',credentials: 'include',
                                    body: formData

                                }
                            ).then(response=>{console.log(response); return response.text()}).then(res=>{
console.log(getCookie("username"))
                                console.log(cookie.loadAll())
                                console.log(res)
                                if(res=="loggedin"){
                                   // document.cookie = "username="+this.state.username;

                                    this.props.callback()
                                }

                            })







                           }} variant="primary" >
                            Login
                        </Button>
                        <Button variant="secondary" >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

Login.propTypes = {};
function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export default Login;