import React from "react";

import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Collapsible from "react-collapsible";
import {FaChevronDown,FaCheck,FaTimes} from 'react-icons/fa'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import '../GlobalVariables'
import {Type} from "react-bootstrap-table2-editor";
import Chart from "react-google-charts";

class TestDetails extends React.Component{
    constructor(props){
        super(props)
        this.state={
            class:"",
            points:"",
            date:this.props.history.location.state.date,topic:this.props.history.location.state.topic,testId:this.props.history.location.state.testId,studentData:[],
            subcategoryData:undefined,missingTestData:[]


        }
        this.getTest()
        //this.getPoints()

    }
    studentColumns = [
        {
            dataField: 'first_name',
            text: 'First Name',
            sort: true
        },{
            dataField: 'last_name',
            text: 'Last Name',
            sort: true
        }, {
            dataField:"mark",
            text: 'Mark',
            sort: true,
            editable:false

        },{
            dataField:"note",
            text: 'Notes',
            editor: {
                type: Type.TEXTAREA
            }}  ];

    missingTestColumns = [
        {
            dataField: 'first_name',
            text: 'First Name',
            sort: true
        },{
            dataField: 'last_name',
            text: 'Last Name',
            sort: true
        }, {
            dataField:"mark",
            text: 'Mark',
            sort: true,
            editable:true

        }/*,{
            dataField:"note",
            text: 'Notes',
            editor: {
                type: Type.TEXTAREA
            }}*/  ];


    getTest(){
        let formData=new FormData()
        formData.append("action","get test")
        formData.append("id",this.state.testId)
        var url = "http://ohrhatorah.appsenses.com/test.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            let chart=[
                ['Name','Mark']]

let below20=0;
            let below40=0;let below60=0;let below80=0;let below90=0; let over90=0;

            res.map((item)=>{
                item.id=parseInt(item.id)
                chart.push([item.last_name,parseInt(item.mark)])
                let m= parseInt(item.mark);
                if(m<=20){
                    below20++
                }else if(m<=40){
below40++
                }else if(m<=60){
below60++
                }else if(m<=80){
below80++
                }else if(m<=90){
below90++
                }
                else{
                    over90++
                }


            })
            function Comparator(a, b) {
                if (a[1] < b[1]) return -1;
                if (a[1] > b[1]) return 1;
                return 0;
            }
            chart=chart.sort(Comparator);
            let data=[['Test', 'Mark'],['20 Or Under',below20],['20 to 40' , below40],['40 to 60' , below60],['60 to 80' , below80],['80 to 90' , below90],['Over 90' , over90]]



                this.setState({studentData:res,class:res[0].class,chart:chart,pie:data})

            let formData=new FormData()
            formData.append("action","get missing tests")
            formData.append("id",this.state.testId)
            var url = "http://ohrhatorah.appsenses.com/test.php"

            fetch(url, {
                    method: 'POST',
                    body: formData

                }
            ).then(response2=>{ return response2.json();}).then(res2=>{
                this.setState({missingTestData:res2})

            })



        })


    }


    defaultSorted = [{
        dataField: 'date', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc' // desc or asc
    }];


    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="tests">Tests</Breadcrumb.Item>
                <Breadcrumb.Item active>{this.state.date}</Breadcrumb.Item>
            </Breadcrumb>
            <div  style={{textAlign:"center",padding:"15px"}} >
                <div className="row tablePadding">
                    <div class="col-sm-3" style={{paddingTop:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Date</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl readOnly
                                         aria-label="Channel Name"
                                         aria-describedby="inputGroup-sizing-default"
                                         value={this.state.date}

                            />
                        </InputGroup>
                    </div>
                    <div className="col-sm-2" style={{paddingTop: "10px"}}>
                        <InputGroup className="mb-3" style={{paddingLeft: "10px"}}>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Class</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl readOnly
                                         aria-label="Channel Name"
                                         aria-describedby="inputGroup-sizing-default"
                                         value={this.state.class}
                                // onChange={(v)=>{this.setState({channelName:v.target.value})}}

                            />
                        </InputGroup>
                    </div>
                    <div className="col-sm-7" style={{paddingTop: "10px",paddingRight:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingRight:"10px"}}>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Topic</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl readOnly
                                         aria-label="Channel Image"
                                         aria-describedby="inputGroup-sizing-default"
                                         value={this.state.topic}


                            />
                        </InputGroup>
                    </div>

                </div>
                <div className="tablePadding" style={{textAlign:"left"}}>
                 <Table delete={false} switch={false} defaultSorted={this.defaultSorted} ref={ n => this.existingSubcategoryTableRef = n } columns={this.studentColumns} reload={this.state.reload} data={this.state.studentData}
                              deleteClick={this.deleteSubFromChannel}                                       rowEvents={{
                        onClick: (e, row, rowIndex) => {
                            console.log(e)
                            if (e.type == "click") {
                                //this.props.stateFunc(row.image)
                                this.props.history.push({


                                    pathname: '/student-details',
                                    state: { class: row.class,studentId: row.student_id,firstName:row.first_name,lastName:row.last_name, classId: row.class_id}


                                })
                            }
                        }
                    }}
                    />
                </div>
                <div>
                    <Chart
                        width={'100%'}
                        height={'500px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.chart}
                        options={{
                            orientation:"horizontal",
                            hAxis:{
                                slantedTextAngle:90
                        },

                            // Material design options
                            chart: {
                                title: 'Test Marks',
                                /*subtitle: 'Sales, Expenses, and Profit: 2014-2017',*/
                            },chartArea: { width: '80%', height: '50%' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '2' }}
                    />

                </div>
                <div style={{textAlign:"center"}}>
                    <div style={{width:"800px",display:"inline-block"}}>
                    <Chart
                        width={'800px'}
                        height={'500px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.pie}
                        options={{
                            title: 'Overall Test Performance',
                            // Just add this option
                            is3D: true,
                        }}
                        rootProps={{ 'data-testid': '2' }}
                    />
                </div>
                </div>
                {this.state.missingTestData.length>0&&
                    <div className="tablePadding" style={{textAlign: "left"}}>
                        <h4>Missing Tests</h4>
                        <Table delete={false} switch={false} defaultSorted={this.defaultSorted}
                               ref={n => this.existingSubcategoryTableRef = n} columns={this.missingTestColumns}
                               data={this.state.missingTestData}
                               deleteClick={this.deleteSubFromChannel} editable={true}
                               editFunc={(oldValue, newValue, row, column) => {
                                   if (oldValue != newValue && column.dataField == "mark") {


                                       let formData = new FormData()
                                       formData.append("action", "save a student test")
                                       formData.append('student_id', row.id)
                                       formData.append('mark', newValue)
                                       formData.append('test_id', this.state.testId)
                                       var url = "http://ohrhatorah.appsenses.com/test.php"

                                       fetch(url, {
                                               method: 'POST',
                                               body: formData

                                           }
                                       ).then(response => {
                                           return response.json();
                                       }).then(res => {

                                           res.map((item) => {
                                               item.id = parseInt(item.id)

                                           })
                                           this.setState({missingTestData: res})

                                           //  this.setState({studentData:res})
                                       })


                                   }
                               }} rowEvents={{
                            onClick: (e, row, rowIndex) => {
                                console.log(e)
                                if (e.type == "click") {
                                    //this.props.stateFunc(row.image)
                                    this.props.history.push({


                                        pathname: '/student-details',
                                        state: {
                                            class: row.class,
                                            studentId: row.student_id,
                                            firstName: row.first_name,
                                            lastName: row.last_name,
                                            classId: row.class_id
                                        }


                                    })
                                }
                            }
                        }}
                        />
                    </div>

                }
                </div>


            </div>

    }
}

export default TestDetails