import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from './components/Table'
import Navbar from './components/Navbar'
import Videos from './components/Videos'
import {BrowserRouter as Router, Route} from "react-router-dom";
import StudentDetails from './components/StudentDetails'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ClassDetails from "./components/ClassDetails";
import SubcategoryDetails from "./components/SubcategoryDetails"
import Upload from "./components/Upload";
import Button from "react-bootstrap/Button";
import {updateChannel} from "./Utils";
import NewChannel from "./components/newChannel";
import NewLesson from "./components/NewLesson";
import  Students from "./components/Students"
import Lessons from "./components/Lessons"
import LessonDetails from "./components/LessonDetails"
import Tests from "./components/Tests"
import NewTest from "./components/NewTest"
import TestDetails from "./components/TestDetails"
import Login from "./components/Login"
import SeatingChart from "./components/SeatingChart"
import cookie from 'react-cookies'
require('es6-promise').polyfill();
require('isomorphic-fetch');

const columns = [{
    dataField: 'id',
    text: 'Id',
    sort: true
}, {
    dataField: 'class',
    text: 'Class',
    sort: true
}];

class Home extends React.Component {
    render() {

        this.props.history.push({
            pathname: '/channel-details'
        })
        return <h2>Home</h2>;
    }
}

class Channels extends React.Component {
    constructor(props) {
        super(props)

        this.state={channels:[],newChannelOpen:false}
        this.setChannels()


    }
    setChannels(){
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url2 = new URL("http://ohrhatorah.appsenses.com/getClasses.php")
        fetch(url2).then(response=>{ return response.json();}).then(res=>{
            res.forEach((a)=>{a.id=parseInt(a.id)})
            this.setState({channels:res})

        })
    }

    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Classes</Breadcrumb.Item>

            </Breadcrumb>
            <NewChannel isDialogOpen={this.state.newChannelOpen} closeFunction={()=>{this.setState({newChannelOpen:false})}}/>
            <div style={{padding: "25px"}}>
                <div style={{paddingTop:'10px',paddingBottom:'10px'}}>

                </div>
                <Table delete={false} switch={false} columns={columns} data={this.state.channels} editFunc={(oldValue, newValue, row, column)=>{updateChannel(oldValue, newValue, row, column)}}
                       rowEvents={{
                           onClick: (e, row, rowIndex) => {
                               console.log(e)
                               if (e.type == "click") {
                                   //this.props.stateFunc(row.image)
                                   this.props.history.push({
                                       pathname: '/class-details',
                                       state:  { class: row.class, classId: row.id}
                                   })
                               }
                           }
                       }}
                />

            </div></div>
    }
}

class App extends React.Component {
    state;

    constructor(props) {
        super(props);
        console.log(cookie.loadAll())
       // this.getAllSubcategories()


            this.state = {


            loggedIn:
                (cookie.load("username")=="yosef"||cookie.load("username")=="guest")


            };
      //  this.checkLoggedIn()


    }

   /* getAllSubcategories() {
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url = new URL("http://appsenses.com/jflix/web/getSubcategories.php")
        fetch(proxyurl + url).then(response => {
            return response.json();
        }).then(res => {
            window.allSubcategories = res;
            window.allSubcategories.forEach((a) => {
                a.text = a.name;
            })

        })
    }*/

    setChannel(val) {
        this.setState({channelImage: val})
    }
    checkLoggedIn(){
        var url = "http://ohrhatorah.appsenses.com/login.php"

        fetch(url).then(response=>{ return response.text()}).then(res=>{

            if(res=="loggedin"){
               // document.cookie = "username="+this.state.username;

                this.setState({loggedIn:true});
            }else{
                // document.cookie = "username="+this.state.username;

                this.setState({loggedIn:false});
            }})


        }

    render() {
       // const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
      //  console.log(useCookies('cookie-name').getAll())
        if (this.state.loggedIn == false) {
            return <Login callback={()=>{this.setState({loggedIn:true})}}/>
        } else {
            return (

                <div>

                    <Router>
                        <Route path="/" component={Navbar}/>
                        <Route path="/classes" component={Channels}></Route>
                        <Route path="/videos" component={Videos}>
                            {/*<Home/>*/}
                        </Route>
                        <Route path="/new-lesson" component={NewLesson}></Route>
                        <Route path="/lessons" component={Lessons}></Route>
                        <Route path="/students" component={Students}></Route>
                        <Route path="/class-details" component={ClassDetails}></Route>
                        <Route path="/lesson-details" component={LessonDetails}></Route>
                        <Route path="/tests" component={Tests}></Route>
                        <Route path="/new-test" component={NewTest}></Route>
                        <Route path="/test-details" component={TestDetails}></Route>
                        <Route path="/seating" component={SeatingChart}></Route>
                        <Route path="/student-details"
                               render={(props) => <StudentDetails {...props} channelImage={this.state}/>}/>
                        <Route path="/subcategory-details" component={ClassDetails}/>
                        <Route path="/home" component={Channels}>

                        </Route>
                        <Route exact path="/" component={Channels}></Route>
                    </Router>
                </div>

            );
        }
    }
}
function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

export default App;
