import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import  NavDropdown from 'react-bootstrap/NavDropdown';


class MyNavbar extends React.Component {
    constructor(props){
        super(props)

    }
    render() {
        return(

        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="home" >Ohr Hatorah</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="home">Home</Nav.Link>
                    <Nav.Link href="classes" >Classes</Nav.Link>
                    <Nav.Link href="students" >Students</Nav.Link>
                   
                    <Nav.Link href="lessons" >Lessons</Nav.Link>
                    <Nav.Link href="tests" >Tests</Nav.Link>
                    <Nav.Link href="seating" >Seating Charts</Nav.Link>
                   {/* <Nav.Link onClick={()=>{window.open('/videos',"_self")}} to='/Videos'>Videos</Nav.Link>
                    <Nav.Link href="upload" >Upload</Nav.Link>*/}
                    {/*<NavDropdown title="Reports" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/test-report">Tests</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown>*/}
                </Nav>

            </Navbar.Collapse>
        </Navbar>
        )
    }
}
export default MyNavbar