import React from "react";
import FilterInput from "./FilterInput";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Collapsible from "react-collapsible";
import {FaChevronDown} from 'react-icons/fa'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import '../GlobalVariables'
import {Type} from "react-bootstrap-table2-editor";
import {addSubcategoryToChannel, deleteChannelFromSubcategory, deleteVideos, updateSubcategoryName} from '../Utils'

class SubcategoryDetails extends React.Component{
    constructor(props){
        super(props)
        this.state={
            subcategoryData:undefined,
            subName:this.props.history.location.state.subName,
            subId:this.props.history.location.state.subId,
            isDialogOpen: false,
            isSubcategoryDialogOpen:false,reload:false,
            tags: [

            ]

        }
        this.getSubcategorysChannels()
        this.getChannels()
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url2 = new URL("http://appsenses.com/jflix/web/getVideos.php"),
            params2 = {subcategory:this.state.subId}
        Object.keys(params2).forEach(key => url2.searchParams.append(key, params2[key]))
        fetch(proxyurl+url2).then(response=>{ return response.json();}).then(res=>{
            console.log(res)
            this.setState({videoData:res})
        })
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.deleteVideo=this.deleteVideo.bind(this)
    }
    getSubcategorysChannels(){
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url = new URL("http://appsenses.com/jflix/web/getChannels.php"),
            params = {subcategory:this.state.subId}
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        fetch(proxyurl+url).then(response=>{ return response.json();}).then(res=>{
            // console.log(res)
            this.setState({subcategoryData:res,reload:!this.state.reload})

        })
    }
    getChannels(){
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url = new URL("http://appsenses.com/jflix/web/getChannels.php")
        fetch(proxyurl+url).then(response=>{ return response.json();}).then(res=>{
            this.setState({channels:res})

        })
    }
    subcategoryColumns = [{
        dataField: 'id',
        text: 'Id',
        sort: true
    }, {
        dataField: 'name',
        text: 'Channel Name',
        sort: true
    }];
    handleDelete(i) {
        const { tags } = this.state;
        this.setState({
            tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }



    videoColumns = [{
        dataField: 'thumb_1',
        text: 'Image',
        formatter:(cell,row,rowIndex)=>{
            return (
                <img src={`${cell}`} onClick= {()=>this.handleShow(row.video_id)} />
            )}
    },{
        dataField: 'id',
        text: 'Id',
        sort: true,
        style:{minWidth:'60px'}
    }, {
        dataField: 'title',
        text: 'Title',
        sort: true,
        editor: {
            type: Type.TEXTAREA
        }
    },{
        dataField: 'description',
        text: 'Description',
        editor: {
            type: Type.TEXTAREA
        }
    },

        {
            dataField:'name',
            editable:false,
            text:'Subcategories',
            formatter:(cell,row,rowIndex)=>{
                let obs = []
                if(cell!=null) {
                    let arr = cell.split(',');

                    arr.forEach((a) => {
                        obs.push({id: a, text: a})
                    })
                }
                console.log(row.id)
                return (
                    <FilterInput videoId={row.id}  suggestions={window.allSubcategories} tags={obs} />
                )}

        }
    ];


    handleClose = () => this.setState({isDialogOpen:false})
    handleShow = (id) => {
        console.log(id)
        this.setState({isDialogOpen:true,selectedVideo:id})}

    handleSubcategoryClose = () => {
        addSubcategoryToChannel(this.subRef,this.state.subId,this.channelsTableRef)
          this.setState({isSubcategoryDialogOpen:false})
    }
    handleSubcategoryShow = () => {
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url = new URL("http://appsenses.com/jflix/web/getSubcategories.php")
        fetch(proxyurl+url).then(response=>{ return response.json();}).then(res=>{
            // console.log(res)
            this.setState({availableSubcategories:res,isSubcategoryDialogOpen:true})
        })

        // this.setState({isSubcategoryDialogOpen:true})
    }
    deleteVideo(){
        let selected=this.videoTableRef.handleGetSelectedData()
        deleteVideos(selected,(s)=>{
            let videos=this.state.videoData
            videos.map((item,itemIndex)=>{
                s.map((se,selectedIndex)=>{
                    if(item.id==se){
                        videos.splice(itemIndex,1)
                        s.splice(selectedIndex,1)

                    }
                })
            })
this.setState({videoData:videos})
        })

    }
    addSubcategory(){
        // this.handleSubcategoryShow()
    }

    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="subcategories">Subcategories</Breadcrumb.Item>
                <Breadcrumb.Item active>Subcategory Details</Breadcrumb.Item>
                <Breadcrumb.Item active>{this.state.subName}</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{textAlign:"center",padding:"15px"}} >
                <Modal show={this.state.isDialogOpen} onHide={this.handleClose} dialogClassName = 'playerModal'  >
                    <Modal.Header closeButton>
                        <Modal.Title>Video Player</Modal.Title>
                    </Modal.Header>
                    <Modal.Body ><iframe  style={{width:"80vw",height:"80vh"}} src={`https://www.youtube.com/embed/${this.state.selectedVideo}`}></iframe></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isSubcategoryDialogOpen} onHide={this.handleSubcategoryClose} dialogClassName = 'playerModal'  >
                    <Modal.Header closeButton>
                        <Modal.Title>Add to channel</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        {this.state.channels!=undefined?  <Table ref={ n => this.subRef = n } columns={this.subcategoryColumns} data={this.state.channels}/>:""}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleSubcategoryClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleSubcategoryClose}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
                         <div class="row">
                    <div class="col-sm-12" style={{paddingTop:"10px"}}>
                        <InputGroup className="mb-3" style={{paddingLeft:"10px"}} >
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Subcategory Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Subcategory Name"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.subName}
                                onChange={(v)=>{this.setState({subName:v.target.value,changed:true})}}
                                onBlur={()=>{
                                    if(this.state.changed==true){

                                        this.setState({changed:false})
                                        updateSubcategoryName(this.state.subId,this.state.subName)
                                    }
                                }}

                            />
                        </InputGroup>
                    </div>

                </div>

                <Collapsible trigger={<CollapsibleText text='Channels with this subcategory'/>} onOpening={()=>{}} style={{background:"black"}}>
                    <div style={{paddingTop:'10px',paddingBottom:'10px'}}>
                        <Button onClick={this.handleSubcategoryShow}>
                            Add to channel
                        </Button>
                    </div>
                    {this.state.subcategoryData!=undefined?  <Table deleteClick={()=>{deleteChannelFromSubcategory(this.channelsTableRef,this.state.subId)}} ref={ ref => this.channelsTableRef=ref } columns={this.subcategoryColumns} reload={this.state.reload} data={this.state.subcategoryData}
                                                                    rowEvents={{
                                                                        onClick: (e, row, rowIndex) => {
                                                                            console.log(e)
                                                                            if (e.type == "click") {
                                                                                //this.props.stateFunc(row.image)
                                                                                this.props.history.push({
                                                                                    pathname: '/channel-details',
                                                                                    state: {channelImage: row.image, channelName: row.name, channelId: row.id}
                                                                                })
                                                                            }
                                                                        }
                                                                    }}
                    />:""}

                </Collapsible>

                <Collapsible lazyRender={true} trigger={<CollapsibleText text="Videos in this subcategory"/>} onOpening={()=>{}} style={{background:"black"}}>
                    {this.state.videoData!=undefined?  <Table ref={ n => this.videoTableRef = n } deleteClick={this.deleteVideo} columns={this.videoColumns} data={this.state.videoData}/>:""}

                </Collapsible>
            </div>
        </div>
    }
}
const CollapsibleText=(props)=>{
    {/*className='col-sm-3 col-lg-2'*/}  {/*className='col-sm-9 col-lg-10'*/}
    return <span style={{paddingLeft:'15px'}} className="row">
      <span style={{width:"250px"}} > {props.text}</span>  <span><FaChevronDown/></span>
    </span>
}
export default SubcategoryDetails