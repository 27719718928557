export const addSubcategoryToChannel = (table,sub,updateTable)=>{
    let selected= table.handleGetSelectedData()
    let   formData = new FormData();
    formData.append("action","add sub to channels")
    formData.append('channels', selected)
    formData.append('sub',sub )
    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
        {
            method: 'POST',
            body: formData
        }
    ).then(responce=>{ return responce.json()}).then(res=>{updateTable.setState({d:res})})

}

export const createSubcategory=(name,callback)=>{
    let   formData = new FormData();
    formData.append("action","create new subcategory")
    formData.append('name', name)
    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
        {
            method: 'POST',
            body: formData
        }
    ).then(responce=>{
        callback()
    })

}
export const createChannel=(name,image,callback)=>{
    let   formData = new FormData();
    formData.append("action","create new channel")
    formData.append('name', name)
    formData.append('image', image)
    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/createChannel.php",
        {
            method: 'POST',
            body: formData
        }
    ).then(responce=>{
        callback()
    })

}

export const updateChannel=(oldValue, newValue, row, column)=>{
   console.log(column)
    if(newValue!= oldValue){
   let   formData = new FormData();
if(column.dataField=="name"){
    formData.append("action","name")
    formData.append('name', newValue)
}else{
    formData.append("action","image")
    formData.append('image', newValue)
}
        formData.append('id', row.id)

    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateChannel.php",
        {
            method: 'POST',
            body: formData  }).then(response=>{})
}}


export const updateSubcategoryName=(sub,name)=>{
        let   formData = new FormData();
        formData.append("action","update name")
        formData.append('name', name)
        formData.append('sub', sub)
        fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
            {
                method: 'POST',
                body: formData  }).then(response=>{})
}
export const insertVideoIntoSubcategoryByName=(videoId,subName)=>{
    let   formData = new FormData();
    formData.append("action","insert video into subcategory by sub name")
    formData.append('videoId', videoId)
    formData.append('subName', subName)
    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
        {
            method: 'POST',
            body: formData  }).then(response=>{})
}
export const removeVideofromSubcategoryByName=(videoId,subName)=>{
    let   formData = new FormData();
    formData.append("action","remove video from subcategory by sub name")
    formData.append('videoId', videoId)
    formData.append('subName', subName)
    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
        {
            method: 'POST',
            body: formData  }).then(response=>{})
}



export const deleteChannelFromSubcategory=(table,sub)=>{
    if( table!=undefined){
    let selected= table.handleGetSelectedData()
        let   formData = new FormData();
        formData.append("action","remove channel from sub")
        formData.append('channels', selected)
        formData.append('sub', sub)
        fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
            {
                method: 'POST',
                body: formData //JSON.stringify(

            }).then(response=>{ return response.json();}).then(res=>{
            table.setState({d:res})

        })
    }
}
export const removeUploaded=(table,data,callback)=>{
   let videos=[];
  let newData=[]
    data.map((da)=>{newData.push(da)})
    data.map((v)=>{videos.push({id:v.videoId})})
    let   formData = new FormData();
    formData.append('action', 'remove uploaded')
    formData.append('videos', JSON.stringify( videos))
    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/upload.php",
        {
            method: 'POST',
            body: formData

        }).then(response=>{
        return response.json()}).then(response=>{
            response.map((video)=>{
                newData.map((d,index)=>{
                    if(d.videoId==video.video_id){
                    newData.splice(index,1)
                    }
                })
            })
newData.map((it,x)=>{it.id=x})
        //console.log(newData)
        callback(newData)
       // table.setData(newData)
    })

}
export const upload=(table,data,subs,callback)=>{
    let selected= table.handleGetSelectedData()
    /*table.setState({
        selected: []
    });
*/
    console.log(selected)
table.unselectAll()

    let videoArray=[];
    let d=[];
    data.map((da)=>{d.push(da)})
let num=0
    selected.map((x)=>{
        let video={}
        video.channelTitle=data[x].snippet.channelTitle
        video.channelId=data[x].snippet.channelId
        video.description=data[x].snippet.description
        video.thumb1=data[x].snippet.thumbnails.default.url
        video.thumb2=data[x].snippet.thumbnails.medium.url
        video.thumb3=data[x].snippet.thumbnails.high.url
        video.title=data[x].snippet.title
        video.id=data[x].videoId
        videoArray.push(video)
        d.splice(x-num,1)
        num++

    })
    d.map((it,x)=>{it.id=x})
    callback(d)
    let   formData = new FormData();
    formData.append('subcategories', subs)
    formData.append('videos', JSON.stringify( videoArray))

    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/upload.php",
        {
            method: 'POST',
            body: formData

        }).then(response=>{

    })


}
export const uploadDelete=(table,data,callback)=>{
    let d=[]
    data.map((a,i)=>{d.push(a)})
    let selected= table.handleGetSelectedData()
    table.setState({
        selectedRowKeys: []
    });
    let num=0
    selected.map((x)=>{
        d.splice(x-num,1)
    num++
    })
    d.map((item,index)=>{item.id=index})
    callback(d)

}
export const subcategoryColumns = [{
    dataField: 'id',
    text: 'Id',
    sort: true
}, {
    dataField: 'name',
    text: 'Channel Name',
    sort: true
}];


export const deleteVideos=(videos,callback)=>{
    let   formData = new FormData();
    formData.append('deleteVideo', videos)
    fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateVideo.php",
        {
            method: 'POST',
            body: formData

        }).then(response=>{
callback(videos)
    })

}

export const updateAttention=(oldValue, newValue, row, column,stateHandle)=>{

    if (oldValue!=newValue&&column.dataField=="attention") {

        let attention;
        if (newValue == "yes") {
            attention = 1
        }
        if (newValue == "no") {
            attention = 0
        }

        let formData = new FormData()
        formData.append("action", "edit attention")
        formData.append('id', row.id)
        formData.append('attention', attention)
        formData.append('studentId', stateHandle.state.studentId)
        var url = "http://ohrhatorah.appsenses.com/lesson.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response => {
            return response.json();
        }).then(res => {
            res.map((item) => {
                item.id = parseInt(item.id)

            })
            stateHandle.setState({studentData: res})
        })


    }
}
export const updatePoints=(oldValue, newValue, row, column,callback)=>{

    if (oldValue!=newValue) {
        if (column.dataField == "points") {



        let formData = new FormData()
        formData.append("action", "edit points")
        formData.append('id', row.id)
        formData.append('points', newValue)

        var url = "http://ohrhatorah.appsenses.com/bank.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response => {
            return response.json();
        }).then(res => {
            res.map((item) => {
                item.id = parseInt(item.id)

            })
            callback(res)
        })

    }else if(column.dataField=="seat"){
            let formData = new FormData()
            formData.append("action", "edit seat")
            formData.append('id', row.id)
            formData.append('seat', newValue)

            var url = "http://ohrhatorah.appsenses.com/students.php"

            fetch(url, {
                    method: 'POST',
                    body: formData

                }
            ).then(response => {
                return response.json();
            }).then(res => {

            })
        }
    }
}



/*
export const deleteSubFromChannel=()=>{
    if( this.existingSubcategoryTableRef!=undefined){
        console.log("here")
        let   formData = new FormData();
        this.removeSubs=this.existingSubcategoryTableRef.handleGetSelectedData()
        formData.append("action","remove sub from channel")
        formData.append('channel', this.state.channelId)
        formData.append('subs', this.removeSubs)
        fetch("https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
            {
                method: 'POST',
                body: formData //JSON.stringify(

            }).then(response=>{ return response.json();}).then(res=>{
            // console.log(res)
            this.setState({subcategoryData:res})
            this.existingSubcategoryTableRef.setState({d:res})

        })
    }}*/
// npm install --save react-dnd-html5-backend@3.0.2