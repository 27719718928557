import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import Button from "react-bootstrap/Button";
//import {updateSubcategoryName} from "../Utils";
import NewSubcategory from "./newSubcategory";
import {updatePoints} from "../Utils";
import Chart from "react-google-charts";

class ClassDetails extends React.Component{
    constructor(props){
        super(props)
       // this.getStudents()
this.state={
    class:this.props.history.location.state.class,
            classId:this.props.history.location.state.classId,studentData:[],
    newSubcategoryOpen:false}
    this.setClass()
    }
    studentColumns = [{
        dataField: 'id',
        text: 'Id',
        sort: true
    }, {
        dataField: 'first_name',
        text: 'First Name',
        sort: true
    },{
        dataField: 'last_name',
        text: 'Last Name',
        sort: true
    },{
        dataField: 'absent',
        text: 'Days Absent',
        sort: true
    },{
        dataField: 'points',
        text: 'Points',
        sort: true
    },{
        dataField: 'seat',
        text: 'Seat',
        sort: true
    }];

    setClass(){
        let formData=new FormData()
        formData.append("action","get class")
        formData.append('id', this.state.classId)
        var url = "http://ohrhatorah.appsenses.com/students.php"

        fetch(url, {
                method: 'POST',
                body: formData

            }
        ).then(response=>{ return response.json();}).then(res=>{
            let chart=[
                ['Name','Work','Work Not Done','Absent']]
            let numberOfTests=0
            let testChart=["Name"]
            res.map((item)=>{
                item.id=parseInt(item.id)
                item.work=parseInt(item.work)
                item.absent=parseInt(item.absent)
                item.no_work=parseInt(item.no_work)
                item.average=parseInt(item.average)
                if(item.points!=undefined){item.points=parseInt(item.points)}
                item.tests=item.tests.split(",")
                if(item.tests.length>numberOfTests){
                    numberOfTests=item.tests.length
                }

                item.tests.map((test,index)=>{
                    item.tests[index]=parseInt(test)
                })

                chart.push([item.last_name,item.work,item.no_work,item.absent])


            })
            for (let i = 0; i < numberOfTests; i++) {
                let testNum=i+1
                testChart.push("Test "+testNum)
            }
            let newTestChart=[]
                newTestChart.push(testChart)

            res.map((item)=>{
                let kidInfo=[item.last_name];
                let missingTests=numberOfTests-item.tests.length
                for (let x = 0; x <missingTests ; x++) {
                    item.tests.push(0)

                }

                item.tests.map((test)=>{
                    kidInfo.push(test)

                })
                newTestChart.push(kidInfo)
            })
            testChart=newTestChart

            console.log(testChart)
            console.log(chart)
            this.setState({studentData:res,chart:chart,testChart:newTestChart})
        })
    }


    getStudents = () => {

        var url = new URL("http://ohrhatorah.appsenses.com/students.php")
        fetch(url).then(response=>{ return response.json();}).then(res=>{
            // console.log(res)
            res.forEach((a)=>{a.id=parseInt(a.id);if(a.points!=undefined){a.points=parseInt(a.points)}})
            this.setState({subcategories:res})
        })

        // this.setState({isSubcategoryDialogOpen:true})
    }

    render() {
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="Home">Classes</Breadcrumb.Item>
                <Breadcrumb.Item active>{this.state.class}</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{paddingTop:"15px",paddingRight:"25px",paddingLeft:"25px"}} >

            {this.state.studentData!=undefined? <Table delete={false}   editFunc={(oldValue, newValue, row, column)=>{{updatePoints(oldValue, newValue, row, column,()=>{})}}}
                                                         columns={this.studentColumns} data={this.state.studentData}
                                                         rowEvents={{
                onClick: (e, row, rowIndex) => {
                    console.log(e)
                    if (e.type == "click") {
                        //this.props.stateFunc(row.image)
                        this.props.history.push({
                            pathname: '/student-details',
                            state: { class: row.class, studentId: row.id,firstName:row.first_name,lastName:row.last_name}
                        })
                    }
                }
            }}
            />:""}
            <div>

                <div style={{paddingBottom:"10px"}}>
                    <Chart
                        width={'100%'}
                        height={'600px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.chart}
                        options={{
                            title: 'Behavior',
                            orientation:"horizontal",
                            hAxis:{
                                slantedTextAngle:90
                            },

                            // Material design options
                            chart: {
                                title: 'Behavior',
                                /*subtitle: 'Sales, Expenses, and Profit: 2014-2017',*/
                            },chartArea: { width: '80%', height: '60%' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '2' }}
                    />

                </div>
                <div style={{paddingBottom:"10px"}}>

                    <Chart
                        width={'100%'}
                        height={'600px'}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.testChart}
                        options={{
                            title: 'Test Marks',
                            orientation:"horizontal",
                            hAxis:{
                                slantedTextAngle:90
                            },

                            // Material design options
                            chart: {
                                title: 'Test Marks',
                                /*subtitle: 'Sales, Expenses, and Profit: 2014-2017',*/
                            },chartArea: { width: '80%', height: '60%' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '2' }}
                    />
                </div>
            </div>
        </div>
        </div>
            }

}
        export default ClassDetails