import React from 'react'
import {WithContext as ReactTags} from "react-tag-input";
import {insertVideoIntoSubcategoryByName,removeVideofromSubcategoryByName} from "../Utils";


const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


class FilterInput extends React.Component {
    constructor(props){
        super(props)
        this.state={
            tags: this.props.tags,
            suggestions: this.props.suggestions
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        }


    handleDelete(i) {
        const { tags } = this.state;
       // tags.map((t)=>{console.log(t)})
        if(this.props.videoId!=undefined){
            removeVideofromSubcategoryByName(this.props.videoId,tags[i].text)
        }
        this.setState({
            tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        console.log(this.props.videoId)
        if(this.props.videoId!=undefined){

            insertVideoIntoSubcategoryByName(this.props.videoId,tag.text)
        }
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }



    render(){
    return  <div>
        <ReactTags
            autofocus={false}
            tags={this.state.tags}
                   suggestions={this.state.suggestions}
                   handleDelete={this.handleDelete}
                   handleAddition={this.handleAddition}
                   handleDrag={this.handleDrag}
                   delimiters={delimiters} />
    </div>
}
}
export default FilterInput