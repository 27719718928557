import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import FilterInput from "./FilterInput";
import { Type } from 'react-bootstrap-table2-editor';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {deleteVideos} from "../Utils";

class Videos extends React.Component{
    constructor(props){
        super(props)
        this.state={
            isDialogOpen: false,

        }
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);

        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var url2 = new URL("http://appsenses.com/jflix/web/getVideos.php")
        fetch(proxyurl+url2).then(response=>{ return response.json();}).then(res=>{
           res.forEach((a)=>{a.id=parseInt(a.id)})
            this.setState({videoData:res})
        })
    }

    handleDelete(i) {
        const { tags } = this.state;
        this.setState({
            tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }


    videoColumns = [{
        dataField: 'thumb_1',
        text: 'Image',
        formatter:(cell,row,rowIndex)=>{
            return (
                <img src={`${cell}`} onClick= {()=>this.handleShow(row.video_id)} />
            )}
    },{
        dataField: 'id',
        text: 'Id',
        sort: true,
        style:{minWidth:'60px'}
    }, {
        dataField: 'title',
        text: 'Title',
        editor: {
            type: Type.TEXTAREA
        },
        sort: true
    },{
        dataField: 'description',
        text: 'Description',
        editor: {
            type: Type.TEXTAREA
        }
    },
        {
            dataField:'name',
            editable:false,
            text:'Subcategories',
            formatter:(cell,row,rowIndex)=>{
                let obs = []
               if(cell!=null) {
                   let arr = cell.split(',');

                   arr.forEach((a) => {
                       obs.push({id: a, text: a})
                   })
               }
                return (
                    <FilterInput videoId={row.id}  suggestions={window.allSubcategories} tags={obs} />
                )}

        },{
            dataField: 'start',
            text: 'Start'

        },{
            dataField: 'channel_title',
            text: 'Channel'

        },{
            dataField: 'channel_id',
            text: 'Channel Id'

        }];
    deleteVideo=()=>{
        let selected=this.videoTableRef.handleGetSelectedData()
        deleteVideos(selected,(s)=>{
            let videos=this.state.videoData
            videos.map((item,itemIndex)=>{
                s.map((se,selectedIndex)=>{
                    if(item.id==se){
                        videos.splice(itemIndex,1)
                        s.splice(selectedIndex,1)

                    }
                })
            })
            this.setState({videoData:videos})
        })

    }



    handleClose = () => this.setState({isDialogOpen:false})
    handleShow = (id) => {
        console.log(id)
        this.setState({isDialogOpen:true,selectedVideo:id})}

    render() {
        const { tags, suggestions } = this.state;
        return <div>
            <Breadcrumb>
                <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Videos</Breadcrumb.Item>

            </Breadcrumb>
        <div style={{padding:"15px"}} >

            <Modal show={this.state.isDialogOpen} onHide={this.handleClose} dialogClassName = 'playerModal'  >
                <Modal.Header closeButton>
                    <Modal.Title>Video Player</Modal.Title>
                </Modal.Header>
                <Modal.Body ><iframe  style={{width:"80vw",height:"80vh"}} src={`https://www.youtube.com/embed/${this.state.selectedVideo}`}></iframe></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Close
                    </Button>
                    {/*<Button variant="primary" onClick={this.handleClose}>*/}
                    {/*Save Changes*/}
                    {/*</Button>*/}
                </Modal.Footer>
            </Modal>


            {this.state.videoData!=undefined?  <Table columns={this.videoColumns} ref={ n => this.videoTableRef = n } deleteClick={this.deleteVideo} data={this.state.videoData}/>:""}


        </div></div>
    }
}
export default Videos
